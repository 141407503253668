import { isEmpty } from './core-utils';

export const getURLFromBaseAndParams = (baseURL: string, params: { [key: string]: string }): string => {
  let url = baseURL;
  if (!isEmpty(params)) {
    url += '?';
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(params)) {
    const value = params[key];
    if (value) {
      const normalizedValue = value.replace(/\s/g, '+');
      url += `${key}=${normalizedValue}&`;
    }
  }
  return url;
};

export const replaceRoutesParams = (url: string, params: Record<string, unknown>): string => {
  return Object.keys(params || {}).reduce((updatedUrl, key) => {
    const regex = new RegExp(`:${key}`, 'g');
    return (updatedUrl || '').replace(regex, String(params[key]));
  }, url);
};

/**
 * Get the first path from the full path.
 *
 * @description
 *  This function gets receives the full path (for example: `/accounts/account_id_1234`) and
 *  returns the first path (`/accounts`). This is useful when we switch between employers or
 *  workspaces.
 * @param fullpath: normally `window.location.pathname`
 * @returns first pathname from full path
 */
export const getFirstPath = (fullpath: string) => {
  const match = fullpath.match(/\/(.+?)[/?]/) ?? fullpath.match(/\/(.+)/);
  return match ? `/${match[1]}` : '/';
};

export const buildUrl = (params: { base?: string; endpoint?: string; query?: Record<string, string | string[]> }) => {
  const { base, endpoint, query } = params;

  const mappedBase = base?.endsWith('/') ? base : `${base}/`;
  const mappedEndpoint = endpoint?.startsWith('/') ? endpoint.slice(1) : endpoint;

  const url = new URL(mappedEndpoint || '', mappedBase);

  if (query) {
    Object.entries(query).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => url.searchParams.append(key, v));
      } else {
        url.searchParams.append(key, value);
      }
    });
  }

  return url.toString();
};
